<template>
    <b-container fluid>
        <b-alert variant="success" :show="importAlert">Успешно</b-alert>
        <b-alert variant="danger" dismissible @dismissed="error = null; showError = false" :show="showError"><span
                v-html="error"></span>
        </b-alert>
        <b-row>
            <b-col md="4" cols="12">
                <b-button @click="generatePack">Генерировать отправление</b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="4" cols="12">
                <b-form-group label="DLV Id">
                    <b-form-input v-model="createPlace.dlvId"/>
                </b-form-group>
            </b-col>
            <b-col md="4" cols="12">
                <b-form-group label="Создание места">
                    <b-button @click="generatePlace">Создать место</b-button>
                </b-form-group>

            </b-col>
        </b-row>


    </b-container>
</template>

<script>

    export default {
        name: 'TestGenerator',
        props: {
            msg: String
        },
        created() {

        },
        data() {
            return {
                loading: false,
                error: null,
                showError: false,
                importAlert: false,
                createPlace: {
                    dlvId: null,
                }
            }
        },
        methods: {
            generatePack() {
                this.$http.post(this.$root.apiUrl + '/api/test/createShipment', {}, {headers: this.$root.getHeaders()})
                    .then(response => {
                        if (response.body.success) {
                            this.importAlert = true;
                            setTimeout(() => this.importAlert = false, 3000);
                        } else {
                            this.error = response.body.errors.map(error => error.message).reduce((acc, value) => acc + '<br>' + value);
                        }
                    }, response => {
                        this.$root.handleErrorResponse(response);
                    });
            },
            generatePlace() {
                if (!this.createPlace.dlvId) {
                    this.error = "Не заполнено dlvId";
                    this.showError = true;
                } else
                    this.$http.post(this.$root.apiUrl + '/api/test/createPlace', this.createPlace, {headers: this.$root.getHeaders()})
                        .then(response => {
                            if (response.body.success) {
                                this.importAlert = true;
                                setTimeout(() => this.importAlert = false, 3000);
                            } else {
                                this.error = response.body.errors.map(error => error.message).reduce((acc, value) => acc + '<br>' + value);
                            }
                        }, response => {
                            this.$root.handleErrorResponse(response);
                        });
            },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h3 {
        margin: 40px 0 0;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }
</style>
